/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: auto !important;
  //max-width: 600px;
  position: relative;
  //NOTE: this is what shows up first when you refresh
  background-color: #06051e;
  //background: url("./assets/background/Gradient.webp") no-repeat center center / cover !important;
  // padding-bottom: env(safe-area-inset-bottom) !important;


}

ion-app {
  --background: #06051e;
  // background: url("./assets/background/Gradient.webp") no-repeat center center /
  //   cover !important;
  margin: auto !important;
  margin-top: calc(env(safe-area-inset-top)) !important;
}

.ion-page{
  //background: #06051e;
}

ion-content {
  //--background: #181f44 !important; #why?
  overflow: auto;
  max-width: 600px;
  align-self: center;
  //--overflow: hidden; //causes problems in other pages!
  &::-webkit-scrollbar {
    display: none;
  }
}

ion-footer{
  max-width: 600px;
}
ion-modal{
  --background: #06051e;
}

.wrapper-btn{
  max-width: 600px !important;
}


// @supports (padding-top: env(safe-area-inset-top)) {
//   body {
//     --safe-area-inset-top: env(safe-area-inset-top);
//     height: calc(100% - var(--safe-area-inset-top));
//   }

//   ion-app {
//     --safe-area-inset-top: env(safe-area-inset-top);
//     height: calc(100% - var(--safe-area-inset-top));
//   }
// }

// @supports (padding-bottom: env(safe-area-inset-bottom)) {
//   body {
//     --safe-area-inset-bottom: env(safe-area-inset-bottom);
//     height: calc(100% - var(--safe-area-inset-bottom));
//   }

//   ion-app {
//     --safe-area-inset-bottom: env(safe-area-inset-bottom);
//     height: calc(100% - var(--safe-area-inset-bottom));
//   }
// }

ion-content {
  --background: #06051e;
  //--background: rgba(0, 0, 0, 0.8);
  // --background: url("./assets/background/Gradient.webp") no-repeat center center /
  //   cover !important;
}

.fab-camera {
  width: 58px;
  height: 58px;
  //margin-right: 16px;
}

.push-modal {
  //height: 15%;
  --height: 120px !important;
  height: 120px !important;
  --background: transparent;
  --box-shadow: none;
  align-items: flex-start;
  .ion-page {
    background: transparent !important;
  }

  .modal-wrapper {
    position: absolute;
    top: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: initial;
    bottom: initial;
    height: 98px;
    --background: transparent !important;
  }

  .range-knob-handle {
    width: 153px !important;
    height: 60px !important;
  }

  .modal-shadow.sc-ion-modal-ios {
    position: absolute;
    background: linear-gradient(
        180deg,
        #0f163d 0%,
        rgba(15, 22, 61, 0) 100%
    ) !important;
  }
}

.option-media-chat-modal {
  --height: 240px;
  --position: absolute;
  display: flex;
  align-items: flex-end;
  --width: 100%;
  --max-width: 432px;
  --border-radius: 20px 20px 0px 0px;

  .ion-page {
    background: transparent !important;
  }

  .range-knob-handle {
    width: 153px !important;
    height: 60px !important;
  }

  .modal-shadow.sc-ion-modal-ios {
    position: absolute;
    background: linear-gradient(
        180deg,
        #0f163d 0%,
        rgba(15, 22, 61, 0) 100%
    ) !important;
  }
}

.default-options-modal {
  .ion-page {
    background: transparent !important;
  }

  --height: 154px;
  --position: absolute;
  display: flex;
  align-items: flex-end;
  --width: 100%;
  --max-width: 460px;
  --border-radius: 20px 20px 0px 0px;


  .range-knob-handle {
    width: 153px !important;
    height: 60px !important;
  }

  .modal-shadow.sc-ion-modal-ios {
    position: absolute;
    background: linear-gradient(
        180deg,
        #0f163d 0%,
        rgba(15, 22, 61, 0) 100%
    ) !important;
  }
}

.input-email-modal {
  .ion-page {
    background: transparent !important;
  }

  --height: 300px;
  --min-height: initial;
  --position: absolute;
  display: flex;
  align-items: flex-end;
  --width: 100%;
  --max-width: 460px;
  --border-radius: 20px 20px 0px 0px;

  .range-knob-handle {
    width: 153px !important;
    height: 60px !important;
  }

  .modal-shadow.sc-ion-modal-ios {
    position: absolute;
    background: linear-gradient(
        180deg,
        #0f163d 0%,
        rgba(15, 22, 61, 0) 100%
    ) !important;
  }
}

.contest-modal {
  .ion-page {
    background: transparent !important;
  }

  --height: 50%;
  --min-height: initial;
  --position: absolute;
  display: flex;
  align-items: flex-end;
  --width: 100%;
  --max-width: 460px;
  --border-radius: 20px 20px 0px 0px;

  .range-knob-handle {
    width: 153px !important;
    height: 60px !important;
  }

  .modal-shadow.sc-ion-modal-ios {
    position: absolute;
    background: linear-gradient(
        180deg,
        #0f163d 0%,
        rgba(15, 22, 61, 0) 100%
    ) !important;
  }
}

.offline-modal {

  .ion-page {
    background: transparent !important;
  }

  --height: 70%;
  --min-height: initial;
  --position: absolute;
  display: flex;
  align-items: flex-end;
  --width: 100%;
  --max-width: 460px;
  --border-radius: 20px 20px 0px 0px;
  --background: transparent;
  --box-shadow: none;
  .range-knob-handle {
    width: 153px !important;
    height: 60px !important;
  }

  .modal-shadow.sc-ion-modal-ios {
    position: absolute;
    background: linear-gradient(
        180deg,
        #0f163d 0%,
        rgba(15, 22, 61, 0) 100%
    ) !important;
  }
}

.send-money-modal {
  --height: 390px;
  --position: absolute;
  display: flex;
  align-items: flex-end;
  --width: 100%;
  --max-width: 460px;
  --border-radius: 20px 20px 0px 0px;
}

.signup-modal {
  --height: 100%;
  --position: absolute;
  display: flex;
  align-items: flex-end;
  --width: 100%;
}

.fullscreen-modal {
  --width: 100% !important;
  --height: 100% !important;
}

.small-modal {
  --height: 96%;
  --position: absolute;
  display: flex;
  align-items: flex-end;
  //--width: 100%;
  --border-radius: 20px 20px 0px 0px;

  @media screen and (min-width:1060px) {
    margin-left: 221px;
  }

  .range-knob-handle {
    width: 153px !important;
    height: 60px !important;
  }

  .modal-shadow.sc-ion-modal-ios {
    position: absolute;
    background: linear-gradient(
        180deg,
        #0f163d 0%,
        rgba(15, 22, 61, 0) 100%
    ) !important;
  }
}

.paid-animation-modal {
  --background:transparent;
  .modal-wrapper {
    background: transparent !important;
  }
}

.prompt-modal {
  --background:transparent;
  .modal-wrapper {
    width: 100%;
    box-shadow: 0px 4px 24px rgba(8, 13, 43, 0.6);
    //background: transparent !important;
    --background: transparent !important;
    min-height: 98px;
    //height: 98px;
    margin-top: 48%;
    border-radius: 24px;
  }

  .range-knob-handle {
    width: 153px !important;
    height: 60px !important;
  }

  .modal-shadow.sc-ion-modal-ios {
    position: absolute;
    background: linear-gradient(
        180deg,
        #0f163d 0%,
        rgba(15, 22, 61, 0) 100%
    ) !important;
  }
}


.modal-info-onboarding {

  .ion-page {
    background: transparent !important;
  }

  --height: 100%;
  --position: absolute;
  display: flex;
  align-items: flex-end;
  --width: 100%;
  --background:transparent;

  .range-knob-handle {
    width: 153px !important;
    height: 60px !important;
  }

  .modal-shadow.sc-ion-modal-ios {
    position: absolute;
    background: linear-gradient(
        180deg,
        #0f163d 0%,
        rgba(15, 22, 61, 0) 100%
    ) !important;
  }
}

.color-white {
  color: white !important;
}

.text-center {
  text-align: center !important;
}

//NOTE: JWPLAYER CUSTOM EDITS ON PLAYER

//Rewind button - bottom bar
.jw-icon-rewind {
  display: none !important;
  width: 0px !important;
}

//Full screen button - bottom bar
.jw-icon-fullscreen {
  display: none !important;
  width: 0px !important;
}

//Play button - bottom bar
.jw-icon-playback {
  display: none !important;
  width: 0px !important;
}

//Play button - middle of video
.jw-display-controls path {
  display: none !important;
}

//PIP icon
.jw-icon-pip {
  display: none !important;
}

//Time slider
.jw-slider-container {
  display: none !important;
}

//Playback timer (0:01 / 0:30)
.jw-text-elapsed {
  display: none !important;
}

//Playback timer (0:01 / 0:30)
.jw-text-countdown {
  display: none !important;
}

//Playback timer (0:01 / 0:30)
.jw-text-duration {
  display: none !important;
}

//Bottom bar
.jw-controlbar {
  padding-bottom: 20% !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
}

//Background color
.jw-wrapper {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.shimmer {
  //background-color: rgba(119, 116, 116, 0.3)
  background-color: rgba(162, 162, 162, 0.3) !important;
}

.enable-jwplayer-controls {

  //NOTE: JWPLAYER CUSTOM EDITS ON PLAYER

  //Rewind button - bottom bar
  .jw-icon-rewind {
    display: none !important;
    width: 0px !important;
  }

  //Full screen button - bottom bar
  .jw-icon-fullscreen {
    display: none !important;
    width: 22px !important;
  }

  //Play button - bottom bar
  .jw-icon-playback {
    display: none !important;
    width: 0px !important;
  }

  //Play button - middle of video
  .jw-display-controls path {
    display: block !important;
    margin: 0 auto !important;
  }

  //PIP icon
  .jw-icon-pip {
    display: none !important;
  }

  //Time slider
  .jw-slider-container {
    display: block !important;
  }

  //Playback timer (0:01 / 0:30)
  .jw-text-elapsed {
    display: block !important;
  }

  //Playback timer (0:01 / 0:30)
  .jw-text-duration {
    display: block !important;
    min-width: 90px;
    text-align: start;
  }

  //Bottom bar
  .jw-controlbar {
    padding-bottom: 0 !important;
    //padding-left: 24px !important;
    //padding-right: 24px !important;
  }

  .jw-icon-volume {
    display: none !important;
  }

  //Background color
  .jw-wrapper {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  .jw-display-icon-next {
    display: none !important;
  }

  .jw-display-icon-rewind {
    display: none !important;
  }

}

.pos-fixed-safari {
  max-width: 600px;
}

.separator {
  border: 4px solid #f0f0f0;
  margin: 20px 0;
  width: 100%;
}



